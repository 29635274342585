import React, {useContext, useState, useEffect, useRef, useCallback} from 'react';
import {useParams, useHistory, useLocation} from "react-router-dom";
import { GlobalContext } from '../../app'
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import Options from '../../components/options/options'
import {useThrottle, useThrottleCallback} from '@react-hook/throttle'
import logo from './assets/spellbook.png'
import seven from './assets/seven-cards.png'
import requirements from './assets/spell-requirements.png'
import duplicates from './assets/spell-duplicates.png'
import nowLive from './assets/now-live.png'
import { isMobile } from "react-device-detect"



import './deck.css'
import SpellAnimationElement from "./spellAnimationElement";
import SpellCard from "./spellCard";


function Deck () {
  let params = useParams()
  let history = useHistory()
  let query = new URLSearchParams(useLocation().search)

  useEffect(()=> {
    let yourbookCode = query.get("yourbook")
    if ( yourbookCode && yourbookCode.length) {
      loadDeckCode(yourbookCode.match(/.{1,2}/g),'YOU')
    }

    let allybookCode = query.get("allybook")
    if ( allybookCode && allybookCode.length ) {
      loadDeckCode(allybookCode.match(/.{1,2}/g),'ALLY')
    }



  }, [])

  const context = useContext(GlobalContext)

  const [showSpellbook, setShowSpellbook] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)
  const [showTTSModal, setShowTTSModal] = useState(false)
  const [showPromoCards, setShowPromoCards] = useState(false)
  const [showDuoCards, setShowDuoCards] = useState(false)
  const [selectedCards, setSelectedCards] = useState([])
  const [selectedAllyCards, setSelectedAllyCards] = useState([])
  const [showSpellDetails, setShowSpellDetails] = useState(false)
  const [selectedSpellDetails, setSelectedSpellDetails] = useState()
  const [deckCodeUrl, setDeckCodeUrl] = useState('')
  const [yourTTSCode, setYourTTSCode] = useState('')
  const [allyTTSCode, setAllyTTSCode] = useState('')
  const [spellAnimations, setSpellAnimations] = useState([])
  const [showAllysSpellbook, setShowAllysSpellbook] = useState(false)
  const [position, setPosition] = useThrottle({x: 0, y:0},15)
  const [showSchool, setShowSchool] = useState({
    charm: true,
    curse: true,
    defense: true,
    divination: true,
    hex: true,
    jinx: true,
    transfiguration: true,
  })

  const handleMouseMove = useCallback((e) => {
    setPosition({x: e.clientX, y: e.clientY})
  })

  const generateDeckCode = () => {
    if (validateSpellbook(selectedCards) === true ){
      const yourArray = selectedCards.map(s=>s.spellCode.toLowerCase()).join('')
      let shareUrl = ''
      if (showDuoCards === true && selectedAllyCards.length > 0) {
        if (validateSpellbook(selectedAllyCards) === true) {
          const allyArray = selectedAllyCards.map(s=>s.spellCode.toLowerCase()).join('')
          shareUrl = 'https://www.thatswizard.com/build-a-deck/?yourbook=' + encodeURIComponent(yourArray) + '&allybook=' + encodeURIComponent(allyArray)
        }
        else {
          return false
        }
      }
      else {
        shareUrl = 'https://www.thatswizard.com/build-a-deck/?yourbook=' + encodeURIComponent(yourArray)
      }
      setDeckCodeUrl(shareUrl)
      openShareModal()
    }
  }

  const generateTTSCode = () => {
    if (validateSpellbook(selectedCards) === true ){
      const yourTTSCode = '!deck ' + selectedCards.map(s=>s.spellCode.toLowerCase()).join('')
      setYourTTSCode(yourTTSCode)
      if (showDuoCards === true && selectedAllyCards.length > 0) {
        if (validateSpellbook(selectedAllyCards) === true) {
          const allyTTSCode = '!deck ' + selectedAllyCards.map(s=>s.spellCode.toLowerCase()).join('')
          setAllyTTSCode(allyTTSCode)
        }
        else {
          return false
        }
      }
      openTTSModal()
    }
  }

  const loadDeckCode = (deckCode, target) => {
    const deckCodeSpells = []
    for (const school in cards) {
      cards[school].map((spell) => {
        if ( deckCode.includes(spell.spellCode.toLowerCase()) ) {
          deckCodeSpells.push(spell)
        }
      })
    }

    const sortedDeckCodeSpells = sortSpellbook(deckCodeSpells)

    /* TODO: check ally deck code */
    if( !validateSpellbook(sortedDeckCodeSpells)){
      history.replace('/build-a-deck')
      return context.addNotification(`Deckcode is not valid.`, 'failure')
    }
    else {
      if( checkPromoCards(sortedDeckCodeSpells).length > 0){
        setShowPromoCards(true)
      }
      if ( target === 'YOU' ){
        setSelectedCards(sortedDeckCodeSpells)
      }
      if ( target === 'ALLY' ){
        setShowDuoCards(true)
        setSelectedAllyCards(sortedDeckCodeSpells)
      }
      return context.addNotification(`Deckcode loaded.`, 'success')

    }
  }

  const togglePromoCards = () => {
    if (showPromoCards === true) {
      if (checkPromoCards(selectedCards).length > 0) {
        return context.addNotification(`You still have promo cards in your spellbook.`, 'failure')
      }
      else if (checkPromoCards(selectedAllyCards).length > 0) {
        return context.addNotification(`You still have promo cards in ally's spellbook.`, 'failure')
      }
      else {
        setShowPromoCards(false)
      }
    }
    else {
      setShowPromoCards(true)
    }
  }

  const toggleDuoCards = () => {
    if (showDuoCards === true) {
      if (checkDuoCards(selectedCards).length > 0) {
        return context.addNotification(`You still have duo cards in your spellbook.`, 'failure')
      }
      else {
        setSelectedAllyCards([]);
        setShowAllysSpellbook(false)
        setShowDuoCards(false)
      }

    }
    else {
      setShowDuoCards(true)
    }
  }

  const checkPromoCards = (checkPromoCardSpells) => {
    let fullCardList = []
    if (checkPromoCardSpells) {
      fullCardList = checkPromoCardSpells
    }
    else {
      if (showDuoCards === true) {
        fullCardList = [...selectedCards, ...selectedAllyCards]
      }
      else {
        fullCardList = [...selectedCards]
      }
    }

    return fullCardList.filter(s=>(s.promo===true))
  }

  const checkDuoCards = (checkDuoCardSpells) => {
    let fullCardList = []
    if (checkDuoCardSpells) {
      fullCardList = checkDuoCardSpells
    }
    else {
      if (showDuoCards === true) {
        fullCardList = [...selectedCards, ...selectedAllyCards]
      }
      else {
        fullCardList = [...selectedCards]
      }
    }
    return fullCardList.filter(s=>(s.duo===true))
  }

  const toggleSpellbook = () => {
    setShowAllysSpellbook(false)
    if (showSpellbook === true) {
      closeSpellbook()
    }
    if (showSpellbook === false) {
      openSpellbook()
    }
  }

  const toggleAllySpellbook = () => {
    setShowAllysSpellbook(true)
    if (showSpellbook === true) {
      closeSpellbook()
    }
    if (showSpellbook === false) {
      openSpellbook()
    }
  }

  const openSpellbook = () => {
    context.openModal()
    setShowSpellbook(true)
  }

  const closeSpellbook = () => {
    context.closeModal()
    setShowSpellbook(false)
  }

  const openShareModal = () => {
    context.openModal()
    setShowShareModal(true)
  }

  const openTTSModal = () => {
    context.openModal()
    setShowTTSModal(true)
  }

  const closeShareModal = () => {
    context.closeModal()
    setShowShareModal(false)
  }

  const closeTTSModal = () => {
    context.closeModal()
    setShowTTSModal(false)
  }

  const cards = {
    charm: [
      {
        spellId: 'Stun',
        spellCode: 'St',
        spellLevel: 1,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Energize',
        spellCode: 'Eg',
        spellLevel: 1,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',
        duo: true,

      },
      {
        spellId: 'Silence',
        spellCode: 'Si',
        spellLevel: 2,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Levitate',
        spellCode: 'Le',
        spellLevel: 3,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Confusion',
        spellCode: 'Co',
        spellLevel: 4,
        spellType: 'charm',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Befuddle',
        spellCode: 'Be',
        spellLevel: 4,
        spellType: 'charm',
        promo: true,
        cardArt: 'Jamie Noble Frier',

      },
    ],
    curse: [
      {
        spellId: 'Paralyze',
        spellCode: 'Pa',
        spellLevel: 1,
        spellType: 'curse',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Explosion',
        spellCode: 'Ex',
        spellLevel: 2,
        spellType: 'curse',
        cardArt: 'Fodor Miklos',

      },
      {
        spellId: 'Embrace Pain',
        spellCode: 'EP',
        spellLevel: 3,
        spellType: 'curse',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Death',
        spellCode: 'De',
        spellLevel: 4,
        spellType: 'curse',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Hide Soul',
        spellCode: 'HS',
        spellLevel: 4,
        spellType: 'curse',
        promo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Conflagration',
        spellCode: 'Cn',
        spellLevel: 4,
        spellType: 'curse',
        duo: true,
        cardArt: 'Jamie Noble Frier',

      },
    ],
    defense: [
      {
        spellId: 'Reflect',
        spellCode: 'Re',
        spellLevel: 1,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Transference',
        spellCode: 'Tr',
        spellLevel: 1,
        spellType: 'defense',
        duo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Disarm',
        spellCode: 'Di',
        spellLevel: 2,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Dispel',
        spellCode: 'Ds',
        spellLevel: 3,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Absorb Magic',
        spellCode: 'AM',
        spellLevel: 3,
        spellType: 'defense',
        promo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Invisibility',
        spellCode: 'Iv',
        spellLevel: 4,
        spellType: 'defense',
        cardArt: 'Jamie Noble Frier',

      },
    ],
    divination: [
      {
        spellId: 'Portents',
        spellCode: 'Pr',
        spellLevel: 1,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Scry',
        spellCode: 'Sc',
        spellLevel: 2,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Foresight',
        spellCode: 'Fs',
        spellLevel: 2,
        spellType: 'divination',
        promo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Arithmancy',
        spellCode: 'Ar',
        spellLevel: 3,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Grim Omen',
        spellCode: 'GO',
        spellLevel: 4,
        spellType: 'divination',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Nevermore',
        spellCode: 'Nm',
        spellLevel: 4,
        spellType: 'divination',
        duo: true,
        cardArt: 'Simon Zhong',

      },
    ],
    hex: [
      {
        spellId: 'Shock',
        spellCode: 'So',
        spellLevel: 1,
        spellType: 'hex',
        cardArt: 'Shunskis',

      },
      {
        spellId: 'Fear',
        spellCode: 'Fe',
        spellLevel: 2,
        spellType: 'hex',
        cardArt: 'Simon Zhong',

      },
      {
        spellId: 'Drain Life',
        spellCode: 'DL',
        spellLevel: 2,
        spellType: 'hex',
        promo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Imprison',
        spellCode: 'Im',
        spellLevel: 2,
        spellType: 'hex',
        duo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Chaos',
        spellCode: 'Ch',
        spellLevel: 3,
        spellType: 'hex',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Mind Control',
        spellCode: 'MC',
        spellLevel: 4,
        spellType: 'hex',
        cardArt: 'Jamie Noble Frier',

      },
    ],
    jinx: [
      {
        spellId: 'Slow',
        spellCode: 'Sl',
        spellLevel: 1,
        spellType: 'jinx',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Miasma',
        spellCode: 'Mi',
        spellLevel: 1,
        spellType: 'jinx',
        duo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Tongue Tie',
        spellCode: 'TT',
        spellLevel: 2,
        spellType: 'jinx',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Enrage',
        spellCode: 'En',
        spellLevel: 2,
        spellType: 'jinx',
        promo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Ignite',
        spellCode: 'Ig',
        spellLevel: 3,
        spellType: 'jinx',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Erase Memory',
        spellCode: 'EM',
        spellLevel: 4,
        spellType: 'jinx',
        cardArt: 'Jamie Noble Frier',

      },
    ],
    transfiguration: [
      {
        spellId: 'Regenerate',
        spellCode: 'Rg',
        spellLevel: 1,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Brittlebones',
        spellCode: 'Br',
        spellLevel: 2,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Shillelagh',
        spellCode: 'Sg',
        spellLevel: 2,
        spellType: 'transfiguration',
        promo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Polymorph',
        spellCode: 'Po',
        spellLevel: 3,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Molten Floor',
        spellCode: 'MF',
        spellLevel: 3,
        spellType: 'transfiguration',
        duo: true,
        cardArt: 'Jamie Noble Frier',

      },
      {
        spellId: 'Shrink',
        spellCode: 'Sh',
        spellLevel: 4,
        spellType: 'transfiguration',
        cardArt: 'Jamie Noble Frier',

      },
    ],
  }

  const toggleSchool = (schoolName) => {
    setShowSchool(prevState => ({...prevState,  [`${schoolName}`]: !showSchool[schoolName]}))
  }

  const renderCards = () => {
    let cardArray = []
    for (const school in cards) {
      cardArray.push(
        <div key={school} className="school">
          <div className="school-header" onClick={() => toggleSchool(school)}>
            <div className={`school-icon icon-${school}`} />
            <h3 className="school-title">{school}</h3>
            <div className={`school-accordion-icon ${!showSchool[school] ? 'icon-add' : 'icon-remove'}`} />
          </div>
          <div className={`spells ${!showSchool[school] ? 'hidden' : ''}`}>
            {renderSchool(cards[school])}
          </div>
        </div>
      )
    }
    return cardArray
  }

  const renderSchool = (school) => {
    return school.map((spell) => {
      let showSpell = true

      if ((spell.promo && !showPromoCards)) {
        showSpell = false
      }
      if ((spell.duo && !showDuoCards)) {
        showSpell = false
      }

      if (showSpell) {
        return renderSpell(spell)
      }
    })
  }

  const renderSpell = (spell) => {
    const pathName = spell.spellId.replace(/\s/g, '').toLowerCase()
    const spellImage = require(`./assets/${pathName}.jpg`)
    return (
      <div key={spell.spellId} className="spell-selection-container">
        <div className="spell-selection-title">{spell.spellId} </div>
        <div className="spell-selection-subtitle">Level {spell.spellLevel} {spell.spellType} {spell.duo ? '(Duo)':''} {spell.promo ? '(Promo)':''}</div>
        <SpellCard mousePosition={position} promoCard={spell.promo} className="spell-selection-image-container">

          <img className={renderSpellImage(spell)}
               src={spellImage}
               onClick={()=>toggleSpell(spell)}/>
          <div className="selected-spell-button clickable spell-select-info-button" onClick={() => openSpellDetails(spell)}><span className="icon-enlarge"></span></div>
        </SpellCard>
      </div>)
  }

  const renderSpellImage = (spell) => {
    if (hasSpell(spell)) {
      return ('spell-image spell-selected')
    }
    return 'spell-image'
  }

  const toggleSpell = (spell) => {
    if (hasSpell(spell)) {
      removeSpell(spell)
    }
    else {
      addSpell(spell)
    }
  }

  const validateSpellbook = (spells) => {

    let valid = true
    if (spells.length !== 7) {
      context.addNotification(`Spellbooks must have 7 spells.`, 'failure')
      return valid = false
    }

    spells.forEach(spell=> {
      if (hasDuplicateSpells(spell,spells) > 1) {
        context.addNotification(`Spellbooks cannot have duplicate spells.`, 'failure')
        return valid = false
      }
      if (hasDuplicateLevels(spell, spells) > 1) {
        context.addNotification(`Spellbook already has a level ${spell.spellLevel} ${spell.spellType[0].toUpperCase()}${spell.spellType.slice(1)}`, 'failure')
        return valid = false
      }
      if (spell.spellLevel > 1) {
        const missingSpellLevels = checkDeckRequirements(spell, spells)
        if (missingSpellLevels.length) {
          context.addNotification(`Spellbook is invalid.`, 'failure')
          return valid = false
        }
      }
    })
    return valid
  }

  const hasDuplicateSpells = (spell,spells) => {
    return spells.filter(s => s.spellId === spell.spellId)
  }

  const hasDuplicateLevels = (spell, spells) => {
    return spells.filter(s => (s.spellLevel === spell.level && s.spellType === spell.spellType))
  }

  const checkDeckRequirements = (spell, spells) => {
    let requiredSpellLevels = []
    for (let i=spell.spellLevel-1; i>0; i--) {
      if (!deckHasLevel(spell,i, spells)) {
        requiredSpellLevels.unshift(i)
      }
    }
    return requiredSpellLevels
  }

  const deckHasLevel = (spell, level, spells) => {
    return spells.find(s => (s.spellLevel === level && s.spellType === spell.spellType))
  }

  const getCurrentSpellbook = () => {
    let currentSpellList = []
    if ( showAllysSpellbook === true ) {
      return selectedAllyCards
    }
    else {
      return selectedCards
    }
  }

  const addSpell = (spell) => {

    let currentSpellList = getCurrentSpellbook()

    if (hasSpell(spell)) {
      return context.addNotification(`${spell.spellId} is already taken.`, 'failure')
    }

    if (currentSpellList.length === 7) {
      return context.addNotification(`Spellbook already has seven spells.`, 'failure')
    }

    if (hasLevel(spell,spell.spellLevel)) {
      return context.addNotification(`Spellbook already has a level ${spell.spellLevel} ${spell.spellType[0].toUpperCase()}${spell.spellType.slice(1)}`, 'failure')
    }

    if (spell.spellLevel > 1) {
      const missingSpellLevels = checkRequirements(spell)
      if (missingSpellLevels.length) {
        return context.addNotification(`Spellbook is missing a level ${arrayToSentence(missingSpellLevels)} ${spell.spellType[0].toUpperCase()}${spell.spellType.slice(1)}.`, 'failure');
      }
    }
    if (showAllysSpellbook === true) {
      setSelectedAllyCards([...selectedAllyCards, spell])
      addSpellAnimation(spell, 'ALLY')
    }
    else {
      setSelectedCards([...selectedCards, spell])
      addSpellAnimation(spell, 'YOU')
    }

    // return context.addNotification(`${spell.spellId} was added to your spellbook.`, 'success')

  }

  const checkRequirements = (spell) => {
    let requiredSpellLevels = []
    for (let i=spell.spellLevel-1; i>0; i--) {
      if (!hasLevel(spell,i)) {
        requiredSpellLevels.unshift(i)
      }
    }
    return requiredSpellLevels
  }

  const hasLevel = (spell, level) => {
    let currentSpellList = getCurrentSpellbook()
    return currentSpellList.find(s => (s.spellLevel === level && s.spellType === spell.spellType))
  }

  const hasSpell = (spell) => {
    let currentSpellList = [...selectedCards, ...selectedAllyCards]
    return currentSpellList.find(s => s.spellId === spell.spellId)
  }

  const removeSpell = (spell) => {
    let currentSpellList = getCurrentSpellbook()
    const lockedSpells = dependentSpells(spell).map(spell => spell.spellId)
    if (lockedSpells.length) {
      return context.addNotification(`${spell.spellId} is required for ${arrayToSentence(lockedSpells)}.`, 'failure')
    }
    const newSpells = currentSpellList.filter(s => (s.spellId !== spell.spellId))
    if (showAllysSpellbook === true) {
      setSelectedAllyCards(newSpells)
    }
    else {
      setSelectedCards(newSpells)
    }

    // return context.addNotification(`${spell.spellId} was removed from your spellbook.`, 'success')


  }

  const dependentSpells = (spell) => {
    let currentSpellList = getCurrentSpellbook()
    return currentSpellList.filter(s => {
      return ( s.spellType === spell.spellType && s.spellLevel > spell.spellLevel)
    })
  }

  const arrayToSentence = (arr) => {
    return arr.slice(0, -2).join(', ') +
      (arr.slice(0, -2).length ? ', ' : '') +
      arr.slice(-2).join(arr.length === 2 ? " and " : ", and ");
  }

  const sortSpellbook = (spells) => {
    const sortedSpellbook = [...spells].sort(function(a,b) {
      var schoolA = a.spellType.toLowerCase();
      var schoolB = b.spellType.toLowerCase();

      var levelA = a.spellLevel;
      var levelB = b.spellLevel;

      if (schoolA < schoolB) return -1;
      if (schoolA > schoolB) return 1;
      if (levelA < levelB) return -1;
      if (levelA > levelB) return 1;
    })
    return sortedSpellbook
  }

  const renderSelectedCards = () => {
    let currentSpellList = getCurrentSpellbook()
    const sortedSpellbook = sortSpellbook(currentSpellList)

    return sortedSpellbook.map((spell) => {
      return renderSelectedSpell(spell)
    })
  }

  const renderSelectedSpell = (spell) => {
    const pathName = spell.spellId.replace(/\s/g, '').toLowerCase()
    const spellImage = require(`./assets/${pathName}-thumb.jpg`)
    return (
      <div key={spell.spellId} className={`selected-spell`}>
        <img className="selected-spell-image" src={spellImage} onDoubleClick={() => removeSpell(spell)} />


        <div className="selected-spell-hover-container">
          <div className="selected-spell-hover-button selected-spell-info-button clickable" onClick={() => openSpellDetails(spell)}>i</div>
          <div className="selected-spell-hover-button selected-spell-remove-button clickable" onClick={() => removeSpell(spell)}><span className="icon-clear"/></div>
        </div>
        <div className="selected-spell-type"><span className={`icon-${spell.spellType}`}></span> {spell.spellLevel}</div>
        <div className="selected-spell-name">{spell.spellId}</div>
      </div>)
  }

  const resetSpellbook = () => {
    setSelectedCards([]);
    setSelectedAllyCards([]);
    context.addNotification(`Spellbooks reset.`, 'success')
    return history.replace('/build-a-deck')
  }

  const renderSpellbookHeader = () => {

      return (
        <div>
          <div className="spellbook-buttons-container">
            <div className={`spellbook-sticky-header clickable ${showAllysSpellbook ? 'spellbook-sticky-header-inactive' : ''}`}
                 onClick={()=>setShowAllysSpellbook(false)}>Your Spellbook</div>
            <div className={`spellbook-sticky-header clickable ${!showAllysSpellbook ? 'spellbook-sticky-header-inactive' : ''} ${!showDuoCards ? 'hidden' : ''}`}
                 onClick={()=>setShowAllysSpellbook(true)} >Ally's Spellbook</div>
          </div>
          <div className={`spellbook-buttons-toggle-slider ${!showDuoCards ? 'hidden' : ''}`}>
            <div className={`spellbook-buttons-toggle-slider-bar ${showAllysSpellbook ? 'show-allys-spellbook' : ''}`}></div>
          </div>
          <div className="spellbook-header-container">
            <div className="spellbook-header-button clickable" onClick={()=>resetSpellbook()}>Reset</div>
            <div className="spellbook-header-button clickable" onClick={()=>generateTTSCode()}>TS Code</div>
            <div className="spellbook-header-button clickable" onClick={()=>generateDeckCode()}>Share</div>
          </div>

          <div className="spellbook-sticky-num-spells">{showAllysSpellbook ? selectedAllyCards.length : selectedCards.length}/7 Spells</div>

        </div>
      )

  }

  const openSpellDetails = (spell) => {
    setSelectedSpellDetails(spell)
    context.openModal()
    setShowSpellDetails(true)
  }

  const closeSpellDetails = () => {
    setSelectedSpellDetails(null)
    context.closeModal()
    setShowSpellDetails(false)
  }

  const renderSpellDetails = (spell) => {
    const pathName = spell.spellId.replace(/\s/g, '').toLowerCase()
    const spellImage = require(`./assets/${pathName}.jpg`)
    return (
      <div className="spell-details-container">
        <img className="spell-details-image" src={spellImage}/>
        <div className="spell-details-artist">Artist: {spell.cardArt}</div>
      </div>)
  }

  const renderSpellAnimations = () => {
    return (
      <div className="spellbook-animation-container">
        {spellAnimations.map(
          (spell) =>
            <SpellAnimationElement
              key={spell.spellKey}
              closeHandler={() => removeSpellAnimation(spell)}
              spellImage={spell.spellImage}
              spellOwner={spell.spellOwner}
            />

        )}
      </div>
    )
  }

  const addSpellAnimation = (spell, spellOwner) => {
    const newSpellAnimation = {
      spellImage:spell.spellId.replace(/\s/g, '').toLowerCase(),
      spellKey: new Date().getTime(),
      spellOwner: spellOwner
    }
    const updatedSpellAnimationsArray = [newSpellAnimation, ...spellAnimations]
    setSpellAnimations(updatedSpellAnimationsArray)

  }

  const removeSpellAnimation = (spell) => {
    let updatedSpellAnimationsArray = [...spellAnimations]
    const removeIndex = updatedSpellAnimationsArray.indexOf(spell)
    updatedSpellAnimationsArray.splice(removeIndex, 1)
    setSpellAnimations(updatedSpellAnimationsArray)
  }

  return (
    <>
    <section className="header-section spellbook-header">
      <img className="header-logo" src={logo} alt="That's Wizard"/>
      {/*<a href="https://www.kickstarter.com/projects/matthewsorrentino/thats-wizard" target="_blank">
        <img className="now-live-logo" src={nowLive} alt="Now Live!" />
      </a>*/}
    </section>

    <div className='content-bg'>
      <div className="content-border">
        <div className="section-container">
          <section className="cards-container">
            <h2 className="header-text">Deckbuilding</h2>

            <div className="regular-text">
              Before each match of That's Wizard, you will build a deck called your Spellbook.
              For deckbuilding, it is important to know that each spell card in That's Wizard
              has a school of magic and a level, indicated by the symbol and number in
              the <span className="bold-text">bottom right corner</span> of the card.
            </div>

            <div className="regular-text">
              If you are playing <span className="bold-text">That's Wizard: Duo</span> (2 vs 2),
              you and your ally share a single deck of spell cards from which to build your spellbooks,
              but you can also choose from the special Duo spells, indicated by the four dot symbol
              near the spell's school of magic symbol. In Duo, you should choose your cards carefully,
              because once you take a spell card, it means that your ally cannot have that same spell in their own spellbook.
            </div>

            <div className="regular-text">
              All players will build their Spellbooks using the following three rules:
            </div>

            <div className="instructions-container">

              <div className="instruction">
                <div className="instruction-text">You must add spell levels from a school of magic <span className="bold-text">sequentially</span>. <span className="nowrap">(1 → 4)</span></div>
                <img className="instruction-image" src={requirements} />
              </div>

              <div className="instruction">
                <div className="instruction-text">You <span className="bold-text">cannot</span> have more than one spell with the same school and level.</div>
                <img className="instruction-image" src={duplicates} />
              </div>

              <div className="instruction">
                <div className="instruction-text">You will pick a total of <span className="bold-text">seven</span> spells to be in your Spellbook.</div>
                <img className="instruction-image" src={seven} />
              </div>

            </div>

            <div className="regular-text">
              There are thousands of possible combinations to choose from, what kind of spellbook will you create?
            </div>

            <div className="card-selection-header">
              <div className="card-selection-title">Build a Deck</div>
            </div>

            <div className="card-selection-options">

              <div className="card-selection-header-toggle-line">
                <div className={`card-selection-header-choice-text clickable ${!showDuoCards ? 'bold-text' : ''}`}>That's Wizard <span className="nowrap">(1 vs 1)</span></div>
                <div className="card-selection-header-choice-container">
                  <input id="two-player-cards" type="checkbox" checked={showDuoCards} readOnly={true} className="card-selection-header-checkbox"/>
                  <label className="card-selection-header-choice" onClick={toggleDuoCards}></label>
                </div>
                <div className={`card-selection-header-choice-text clickable ${showDuoCards ? 'bold-text' : ''}`}>That's Wizard: Duo <span className="nowrap">(2 vs 2)</span></div>
              </div>

              <div className="card-selection-header-toggle-line">
                <div className={`card-selection-header-toggle-text clickable ${showPromoCards ? 'bold-text' : ''}`}>Show Promo Cards:</div>
                <div className="card-selection-header-toggle-container">
                  <input id="promotional-cards" type="checkbox" checked={showPromoCards} readOnly={true} className="card-selection-header-checkbox"/>
                  <label className="card-selection-header-toggle" onClick={togglePromoCards}></label>
                </div>
              </div>

            </div>

            <div className="cards" onMouseMove={handleMouseMove}>

                <div className="card-selection-container">
                  {renderCards()}
                </div>

                <div  className={`spellbook-overlay-container clickable ${showSpellbook ? 'show-spellbook-overlay' : ''}`}
                      onClick={closeSpellbook}>
                </div>

                <div className={`spellbook-sticky-container ${showSpellbook ? 'show-spellbook' : ''}`}>
                  <div className="spellbook-sticky-toggle-button clickable" onClick={closeSpellbook}>
                    <div className="icon-chevron-down" />
                  </div>
                  <div className="spellbook-sticky-container-inner black-scrollable">
                    {renderSpellbookHeader()}
                    {renderSelectedCards()}
                  </div>
                </div>

            </div>

          </section>
        </div>
      </div>
    </div>

    <div className={`spellbook-container clickable ${!showAllysSpellbook ? 'active-spellbook' : ''}`} onClick={toggleSpellbook}>
      <div className="spellbook-amount">{selectedCards.length}</div>
      <div className="icon-book spellbook-icon"/>
    </div>

    <div className={`spellbook-container ally-spellbook clickable ${showDuoCards ? '' : 'hidden'} ${showAllysSpellbook ? 'active-spellbook' : ''}`} onClick={toggleAllySpellbook}>
      <div className="spellbook-amount">{selectedAllyCards.length}</div>
      <div className="icon-book spellbook-icon"/>
    </div>

    {renderSpellAnimations()}

    <div className={`spellbook-modal-container ${showSpellDetails ? 'show-modal' : ''}`}>
      <div className="spellbook-modal-background" onClick={()=> closeSpellDetails()}/>
      <div className="spellbook-modal-content blue-scrollable">
        {selectedSpellDetails ? renderSpellDetails(selectedSpellDetails) : ''}
      </div>
      <div className={`close-button icon-clear ${showSpellDetails ? 'show-modal-close' : ''}`} onClick={closeSpellDetails}/>
    </div>

    <div className={`spellbook-modal-container ${showShareModal ? 'show-modal' : ''}`}>
      <div className="spellbook-modal-background" onClick={closeShareModal}/>
      <div className="spellbook-modal-content blue-scrollable">
        <div className="share-container">
          <div className="share-container-header">Share your spellbook with your fellow witches and wizards!</div>
          <div className="deck-code-input" type="text">{deckCodeUrl}</div>
          <div className="share-button-facebook">
            <FacebookShareButton url={deckCodeUrl} quote="Get your wands ready to duel and check out this custom spellbook for That's Wizard! Create your own and learn more about the game! @thatswizard">
              <div className="share-spellbook-button icon-facebook"/>
            </FacebookShareButton>
          </div>
          <div className="share-button-twitter">
            <TwitterShareButton url={deckCodeUrl} title="Get your wands ready to duel and check out this custom spellbook for That's Wizard! Create your own and learn more about the game!" via="manaworksgames" >
              <div className="share-spellbook-button icon-twitter"/>
            </TwitterShareButton>
          </div>
        </div>
      </div>
      <div className={`close-button icon-clear ${showShareModal ? 'show-modal-close' : ''}`} onClick={closeShareModal}/>
    </div>

    <div className={`spellbook-modal-container ${showTTSModal ? 'show-modal' : ''}`}>
      <div className="spellbook-modal-background" onClick={closeTTSModal}/>
      <div className="spellbook-modal-content blue-scrollable">
        <div className="tts-container">
          <div className="share-container-header">Import your spellbooks into Tabletop Simulator!</div>
          <p className="tts-instructions">To use this deck code you will type in the <span className="bold-text">!deck</span> command into the chat window of TS, followed by the unique code for your spellbook.</p>
          <p className="tts-instructions">Don't worry, <span className="bold-text">!deck</span> commands will not be broadcast to the other players, so you will not give away what spells you took.</p>
          <p className="tts-instructions">The seven cards will then automatically be dealt into your hand as long as you are at a seat.</p>
          <div className="tts-code-input-label">Your Spellbook</div>
          <div className="tts-code-input" type="text">{yourTTSCode}</div>
          <div className={`tts-code-input-label ${!showDuoCards ? 'tts-code-input-hidden' : ''}`}>Ally's Spellbook</div>
          <div className={`tts-code-input ${!showDuoCards ? 'tts-code-input-hidden' : ''}`} type="text">{allyTTSCode}</div>
        </div>
      </div>
      <div className={`close-button icon-clear ${showTTSModal ? 'show-modal-close' : ''}`} onClick={closeTTSModal}/>
    </div>

    </>
  )
}

export default Deck
