import React, {useContext, useState, useEffect, useRef, useLayoutEffect, useCallback} from 'react';
import {useParams, useHistory} from "react-router-dom";
import { isMobile } from "react-device-detect"

function SpellCard (props) {

  function limitNumberWithinRange(num, min, max){
    const MIN = min || -8;
    const MAX = max || 8;
    const parsed = parseInt(num)
    return Math.min(Math.max(parsed, MIN), MAX)
  }

  const [rotation, setRotation] = useState({x:0,y:0})
  const [gradient, setGradient] = useState({lp:0,tp:0})
  const [isShown, setIsShown] = useState(false)


  let rect = null

  const resetRotation = () => {
    setRotation({x:0,y:0})
    setGradient({lp:0,tp:0})
    setIsShown(false)
  }

  const spellCardContainer = useCallback(node => {
    if(isShown){
      if (node !== null) {
        if (rect === null) {
          rect = node.getBoundingClientRect()
        }
        let x = limitNumberWithinRange((((props.mousePosition.x - rect.left)/rect.width)*20)-10) //x position within the element.
        let y = limitNumberWithinRange((((props.mousePosition.y - rect.top)/rect.height)*20)-10)  //y position within the element.
        setRotation({x,y})

        let px = Math.abs(Math.floor(100 / rect.width * ((props.mousePosition.x - rect.left)/rect.width))-100)
        let py = Math.abs(Math.floor(100 / rect.height * ((props.mousePosition.y - rect.top)/rect.height))-100)
        let lp = ((props.mousePosition.x - rect.left)/rect.width)*95
        let tp = ((props.mousePosition.y - rect.top)/rect.height)*95
        setGradient({lp,tp})

      }
    }
  }, [props.mousePosition]);


  if ( props.promoCard == true ){
    return (
      <div className="spell-selection-image-container" ref={spellCardContainer}
           onMouseEnter={() => {if(!isMobile) setIsShown(true)} }
           onMouseLeave={() => resetRotation()}
           style={{'transform': `rotateY(${rotation.x}deg) rotateX(${rotation.y}deg)`}}>
        <div className="holo-foil-container"
             style={{'backgroundPosition': `${gradient.lp}% ${gradient.tp}%`}} />
        {props.children}

      </div>
    )
  }

  return (
    <div className="spell-selection-image-container" ref={spellCardContainer}
         onMouseEnter={() => {if(!isMobile) setIsShown(true)} }
         onMouseLeave={() => resetRotation()}
         style={{'transform': `rotateY(${rotation.x}deg) rotateX(${rotation.y}deg)`}}>
      {props.children}

    </div>
  )

}

export default SpellCard
