import React from 'react'

import logo from './assets/howtoplay.png'
import nowLive from './assets/now-live.png'
import './learn.css'

function Learn () {

  return (
    <>
      <section className="header-section learn-header">
        <img className="header-logo" src={logo} alt="That's Wizard"/>
        {/*<a href="https://www.kickstarter.com/projects/matthewsorrentino/thats-wizard" target="_blank">
          <img className="now-live-logo" src={nowLive} alt="Now Live!" />
        </a>*/}
      </section>

      <div className='content-bg'>
        <div className="content-border">
        <div className="section-container">

          <section className="learn-sections">

            <h2 className="header-text">Basic Rules</h2>

            <div className="regular-text">
              Learn the basics of That's Wizard by watching this quick video!
            </div>

            <div className='promo-video-container constrained'>
              <div className="iframe-container">
                <iframe title='How To Play - Thats Wizard' width="1920" height="1080" src="https://www.youtube.com/embed/hj5Q57JY3Mo?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

            <h2 className="header-text">Watch it played</h2>

            <div className="regular-text">
              Want to learn more? Watch this full playthrough video, or download the <a href="/That's Wizard Rulebook.pdf" target="_blank" download>official rulebook</a>!
            </div>

            <div className='promo-video-container constrained'>
              <div className="iframe-container">
                <iframe title='Full Playthrough - Thats Wizard' width="1920" height="1080" src="https://www.youtube.com/embed/estmG0wkNak?rel=0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>

          </section>

        </div>
        </div>
      </div>

    </>
  )
}

export default Learn
